"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ReportNullifierProof = exports.ReportNonNullifierProof = exports.ReportIdentityProof = exports.DataProof = exports.DailyClaimProof = void 0;
var DailyClaimProof_1 = require("./DailyClaimProof");
Object.defineProperty(exports, "DailyClaimProof", { enumerable: true, get: function () { return DailyClaimProof_1.DailyClaimProof; } });
var DataProof_1 = require("./DataProof");
Object.defineProperty(exports, "DataProof", { enumerable: true, get: function () { return DataProof_1.DataProof; } });
var ReportIdentityProof_1 = require("./ReportIdentityProof");
Object.defineProperty(exports, "ReportIdentityProof", { enumerable: true, get: function () { return ReportIdentityProof_1.ReportIdentityProof; } });
var ReportNonNullifierProof_1 = require("./ReportNonNullifierProof");
Object.defineProperty(exports, "ReportNonNullifierProof", { enumerable: true, get: function () { return ReportNonNullifierProof_1.ReportNonNullifierProof; } });
var ReportNullifierProof_1 = require("./ReportNullifierProof");
Object.defineProperty(exports, "ReportNullifierProof", { enumerable: true, get: function () { return ReportNullifierProof_1.ReportNullifierProof; } });
