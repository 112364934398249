"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DailyClaimProof = void 0;
const circuits_1 = require("@unirep/circuits");
const types_1 = require("./types");
/**
 * Verify the voter identity for voting the report
 */
class DailyClaimProof extends circuits_1.BaseProof {
    constructor(publicSignals, proof, prover) {
        super(publicSignals, proof, prover);
        this.input = {
            epochKey: 0,
            control0: 1,
            control1: 2,
            dailyEpoch: 3,
            dailyNullifier: 4,
        };
        this.epochKey = BigInt(publicSignals[this.input.epochKey]);
        this.control0 = BigInt(publicSignals[this.input.control0]);
        this.control1 = BigInt(publicSignals[this.input.control1]);
        this.dailyEpoch = BigInt(publicSignals[this.input.dailyEpoch]);
        this.dailyNullifier = BigInt(publicSignals[this.input.dailyNullifier]);
        const { nonce, epoch, attesterId, revealNonce, chainId } = (0, circuits_1.decodeEpochKeyControl)(this.control0);
        this.nonce = nonce;
        this.epoch = epoch;
        this.attesterId = attesterId;
        this.revealNonce = revealNonce;
        this.chainId = chainId;
        const { minRep, maxRep, proveMinRep, proveMaxRep, proveZeroRep, proveGraffiti, } = (0, circuits_1.decodeReputationControl)(this.control1);
        this.minRep = minRep;
        this.maxRep = maxRep;
        this.proveMinRep = proveMinRep;
        this.proveMaxRep = proveMaxRep;
        this.proveZeroRep = proveZeroRep;
        this.proveGraffiti = proveGraffiti;
        this.circuit = types_1.UnirepSocialCircuit.dailyClaimProof;
    }
}
exports.DailyClaimProof = DailyClaimProof;
