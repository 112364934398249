"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UnirepSocialCircuit = void 0;
var UnirepSocialCircuit;
(function (UnirepSocialCircuit) {
    UnirepSocialCircuit["reportIdentityProof"] = "reportIdentityProof";
    UnirepSocialCircuit["reportNullifierProof"] = "reportNullifierProof";
    UnirepSocialCircuit["reportNonNullifierProof"] = "reportNonNullifierProof";
    UnirepSocialCircuit["dailyClaimProof"] = "dailyClaimProof";
})(UnirepSocialCircuit = exports.UnirepSocialCircuit || (exports.UnirepSocialCircuit = {}));
